var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',{attrs:{"page":_vm.$route.name},scopedSlots:_vm._u([{key:"the-header",fn:function(){return [_c('the-header',{attrs:{"page":_vm.$route.name,"image":"dossier.jpg"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(_vm._s(_vm.translated_view_value("header.title")))]},proxy:true},(_vm.documentDetails)?{key:"default",fn:function(){return [_c('h3',[_vm._v(_vm._s(_vm.dossierName))]),_c('p',[_vm._v(" "+_vm._s(_vm.translated_view_value("last_modified"))),_c('br'),_vm._v(" "+_vm._s(_vm.formatIsoDateTime(_vm.documentDetails.lastModified))+" ")])]},proxy:true}:null],null,true)})]},proxy:true},{key:"default",fn:function(){return [_c('router-link',{staticClass:"link-with-icon back",attrs:{"to":{
        name: 'dossier',
        query: {
          selectedAccountIds: _vm.selectedAccountIds,
        },
      },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var navigate = ref.navigate;
return [_c('span',{attrs:{"role":"link","tabindex":"0"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.translated_view_value("back_to_overview"))+" ")])]}}])}),_c('loader',{attrs:{"data-loading":_vm.isLoading}},[(_vm.documentDetails)?[_c('document-layout',[_c('div',{staticClass:"title",attrs:{"slot":"header"},slot:"header"},[_c('h1',[_vm._v(_vm._s(_vm.documentDetails.name))]),_c('div',{staticClass:"display-items"},[_c('div',{staticClass:"display-group display-group-columns"},[_c('div',{staticClass:"display-item"},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.translated_view_value("className"))+" ")]),_c('div',{staticClass:"font-weight-thin"},[_vm._v(" "+_vm._s(_vm.documentDetails.className || "-")+" ")])]),_c('div',{staticClass:"display-item"},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.translated_view_value("year"))+" ")]),_c('div',{staticClass:"font-weight-thin"},[_vm._v(" "+_vm._s(_vm.documentDetails.year || "-")+" ")])])])])]),_c('document-attachments',{attrs:{"slot":"first-column","account-id":_vm.accountId,"document-details":_vm.documentDetails},slot:"first-column"})],1)]:[_c('div',{staticClass:"detail-page"},[_c('h1',[_vm._v(_vm._s(_vm.translated_view_value("no_dossier_found_title")))]),_c('p',[_vm._v(_vm._s(_vm.translated_view_value("no_dossier_found_message")))])])]],2)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }