import axios, { AxiosInstance, AxiosPromise, AxiosResponse } from "axios";
import { injectBearerToken, requestReject } from "@/api/axios";
import { Account } from "@/interfaces/Account";
import { isEmpty } from "lodash";
import { parseISO } from "date-fns";

export interface MFilesAPIDocument {
  id: number;
  vault: string;
  name: string;
  year: string;
  className: string;
  lastModified: string;
}

export interface MFilesApiDocumentAttachment {
  id: string;
  fileName: string;
  mediaType: string;
  size: string;
}

export interface MFilesDocumentDetails extends MFilesAPIDocument {
  attachments: MFilesApiDocumentAttachment[];
}

export interface DossierDocument {
  id: number;
  vault: string;
  name: string;
  year: string;
  className: string;
  lastModified: Date;
  account: Account;
}

const toDossierDocument = (
  doc: MFilesAPIDocument,
  account: Account
): DossierDocument => {
  return {
    id: doc.id,
    account: account,
    className: doc.className,
    lastModified: isEmpty(doc.lastModified)
      ? new Date(0)
      : parseISO(doc.lastModified),
    name: doc.name,
    vault: doc.vault,
    year: doc.year,
  };
};

const axiosInstance = (): AxiosInstance => {
  const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_DMS_URL,
    timeout: process.env.VUE_APP_API_REQUEST_TIMEOUT as number | undefined,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  axiosInstance.interceptors.request.use(injectBearerToken, requestReject);
  return axiosInstance;
};

const mFiles = {
  addDocument: (accountId: string, formData: FormData) => {
    return axiosInstance().post(`/documents/${accountId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getDocuments: (accountId: string): AxiosPromise<MFilesAPIDocument[]> => {
    return axiosInstance().get(`/documents/${accountId}`);
  },
  getDocumentsWithAccount: (account: Account): Promise<DossierDocument[]> => {
    return mFiles
      .getDocuments(account.id)
      .then((item) => item.data)
      .then((item) => item.map((doc) => toDossierDocument(doc, account)));
  },
  getDocumentDetails: (
    accountId: string,
    vault: string,
    documentId: number
  ): AxiosPromise<MFilesDocumentDetails> => {
    return axiosInstance().get(
      `/documents/${accountId}/${vault}/${documentId}`
    );
  },
  download: (
    accountId: string,
    vault: string,
    documentId: number,
    attachmentId: string,
    contentType: string
  ): Promise<AxiosResponse> => {
    return axiosInstance().get(
      `/documents/${accountId}/${vault}/${documentId}/download/${attachmentId}`,
      {
        responseType: "arraybuffer",
        headers: { Accept: contentType },
      }
    );
  },
  preview: (
    accountId: string,
    vault: string,
    documentId: number,
    attachmentId: string,
    contentType: string
  ): Promise<AxiosResponse> => {
    return axiosInstance().get(
      `/documents/${accountId}/${vault}/${documentId}/view/${attachmentId}`,
      {
        responseType: "arraybuffer",
        headers: { Accept: contentType },
      }
    );
  },
};

export default mFiles;
