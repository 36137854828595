




import { Component, Prop, Vue } from "vue-property-decorator";
import AttachmentRow from "@/components/attachments/AttachmentRow.vue";
import AttachmentList from "@/components/attachments/AttachmentList.vue";
import { DossierDocumentAttachment } from "@/interfaces/MFiles";
import mFiles, { MFilesDocumentDetails } from "@/api/mFiles";

@Component({
  components: { AttachmentList, AttachmentRow },
})
export default class DocumentAttachments extends Vue {
  @Prop()
  private accountId!: string;

  @Prop()
  private documentDetails!: MFilesDocumentDetails;

  get attachments(): DossierDocumentAttachment[] {
    return this.documentDetails?.attachments.map((attachment) => {
      return {
        id: attachment.id,
        contentType: attachment.mediaType,
        name: attachment.fileName,
        size: attachment.size,
        download: () =>
          mFiles.download(
            this.accountId,
            this.documentDetails.vault,
            this.documentDetails.id,
            attachment.id,
            attachment.mediaType
          ),
        preview: (contentType: string) =>
          mFiles.preview(
            this.accountId,
            this.documentDetails.vault,
            this.documentDetails.id,
            attachment.id,
            contentType
          ),
      };
    });
  }
}
